import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import { Container, Section, Wysiwyg } from 'src/components/Base'
import NewsGrid from 'src/components/NewsGrid'
import NewsHeader from 'src/components/NewsHeader'
import Seo from 'src/components/Seo'
import SocialShare from 'src/components/SocialShare'
import { Video, Podcast } from 'src/sections'

import { cImage, cMediaSection, cSocialShare } from './styles.module.scss'

const BlogSingle = ({ data, pageContext }) => {
  const news = data.wpNeuigkeit
  const pageSlug =
    news.language.locale.replace('_', '-').toLowerCase() + '/news/' + news.slug
  let relatedArticles =
    news.atspNews.newsRelatedCategory?.contentNodes.nodes || []

  // check if there are posts in the related category
  if (news.atspNews.newsRelatedCategory && relatedArticles.length > 0) {
    // remove all posts, which don't have the atspNews property filled
    relatedArticles = news.atspNews.newsRelatedCategory.contentNodes.nodes.filter(
      (post) => post.atspNews && post.title !== news.title
    )
  }

  const {
    newsButton,
    relatedNewsHeading,
  } = data.allWp.nodes[0].themeGeneralSettings.atspSinglePostOptions.newsOptions

  return (
    <>
      <Seo
        title={news.seo.title}
        locale={news.language.locale}
        description={news.seo.metaDesc}
      />
      <Section>
        <NewsHeader heading={news.title} date={news.atspNews.newsDate} />
        <GatsbyImage
          className={cImage}
          image={getImage(news.atspNews.newsImage.localFile.childImageSharp)}
          alt={news.atspNews.newsImage.altText}
          objectFit="contain"
        />
        <Container>
          <Wysiwyg>{news.content}</Wysiwyg>
        </Container>
      </Section>
      {!!news.atspNews.newsPodcast.link && !!news.atspNews.newsPodcast.id && (
        <Podcast
          heading={news.atspNews.newsPodcast.title}
          text={news.atspNews.newsPodcast.text}
          link={news.atspNews.newsPodcast.link}
          id={news.atspNews.newsPodcast.id}
          className={cMediaSection}
        />
      )}
      {!!news.atspNews.newsVideo.videoLink && (
        <Video
          heading={news.atspNews.newsVideo.title}
          text={news.atspNews.newsVideo.text}
          video={news.atspNews.newsVideo.videoLink}
          className={cMediaSection}
          isBoxed={false}
        />
      )}
      <Section className={cSocialShare}>
        <Container>
          <SocialShare pageTitle={news.title} pageSlug={pageSlug} />
        </Container>
      </Section>
      <NewsGrid
        allNewsButton={newsButton}
        heading={relatedNewsHeading}
        posts={relatedArticles}
        hasPagination={false}
        maxPosts={3}
        postsPerPage={3}
      />
    </>
  )
}

export const newsQuery = graphql`
  query DefaultNewsById($id: String!) {
    wpNeuigkeit(id: { eq: $id }) {
      title
      slug
      language {
        locale
      }
      seo {
        title
        metaDesc
      }
      content
      atspNews {
        newsDate
        newsImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        newsPodcast {
          id
          link
          title
          text
        }
        newsVideo {
          title
          text
          videoLink
        }
        newsRelatedCategory {
          contentNodes {
            nodes {
              ... on WpNeuigkeit {
                id
                title
                link
                slug
                excerpt
                language {
                  locale
                }
                atspNews {
                  newsDate
                  newsImageTeaser {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allWp {
      nodes {
        themeGeneralSettings {
          atspSinglePostOptions {
            newsOptions {
              newsButton {
                title
                url
              }
              relatedNewsHeading
            }
          }
        }
      }
    }
  }
`

export default BlogSingle
