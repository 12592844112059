import React from 'react'
import PropTypes from 'prop-types'
import { cNewsHeader, cLabel } from './styles.module.scss'
import { Heading } from 'src/components/Base'
import classNames from 'classnames'

const NewsHeader = ({ heading, date }) => {
  return (
    <div className={cNewsHeader}>
      <span className={classNames(cLabel, 'text-white')}>{date}</span>
      <Heading as="h1" size={1} color="dark" isCentered>
        {heading}
      </Heading>
    </div>
  )
}

NewsHeader.PropTypes = {
  heading: PropTypes.string.isRequred,
  date: PropTypes.string.isRequred,
}

export default NewsHeader
